<template>
  <div class="security-wrap background-color-4 padding15">
    <p class="font24">{{ $t('profile.security') }}</p>
    <div class="content-item ub ub-btw padding-top15 padding-bottom15" v-for="(item, index) in Object.keys(btnList)" :key="index" @click="btnListFn(item)">
      <div class="main-text-1 font16 ub ub-h-c">
        <p>{{$t(`profile.${item}`)}}</p>
      </div>
      <div class="ub ub-cend">
        <div class="ub ub-cend" v-if="btnList[item].rightTmp === 'box'">
          <div v-if="phoneAndEmail[item]">
            <p style="color:#BAB7BF">{{$t(`profile.${btnList[item].rightLabel}`)}}</p>
          </div>
          <div v-else class="ub ub-cen">
            <van-icon color="#D61F1F" name="info-o" />
            <p style="color:#D61F1F" class="margin-left8">{{ $t(`profile.disabled`) }}</p>
          </div>
          <div class="ub ub-cen">
            <img src="@/assets/images/profile/rightArrow.svg" alt="" srcset="">
          </div>
        </div>
        <div class="ub ub-cend" v-if="btnList[item].rightTmp === 'switch'">
          <van-switch size="20" inactive-color="#BAB7BF" v-model="btnList[item].checked" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Switch, Icon } from 'vant'
import Vue from 'vue'
Vue.use(Switch)
Vue.use(Icon)

export default {
  name: 'ProfileSecurity',
  components: {},
  computed: {
    phoneAndEmail () {
      return {
        phone: !!this.$store.state.userInfo?.mobile,
        email: !!this.$store.state.userInfo?.email,
        loginPwd: true,
        withdrawPwd: true
      }
    }
  },
  data () {
    return {
      userInfo: {},
      btnList: {
        phone: {
          path: '',
          rightLabel: 'linked',
          rightStatus: 'phone',
          rightTmp: 'box',
          rightColor: ''
        },
        email: {
          path: '',
          rightLabel: 'linked',
          rightStatus: 'email',
          rightTmp: 'box',
          rightColor: ''
        },
        loginPwd: {
          path: '',
          rightLabel: 'change',
          rightStatus: true,
          rightTmp: 'box',
          rightColor: ''
        },
        withdrawPwd: {
          path: '',
          rightLabel: 'change',
          rightStatus: 'Linked',
          rightTmp: 'box',
          rightColor: ''
        }
        // faceID: {
        //   path: '',
        //   rightLabel: 'linked',
        //   rightStatus: 'Linked',
        //   rightTmp: 'switch',
        //   rightColor: '',
        //   checked: false
        // },
        // patternPwd: {
        //   path: '',
        //   rightLabel: 'linked',
        //   rightStatus: 'Linked',
        //   rightTmp: 'switch',
        //   rightColor: '',
        //   checked: false
        // }
      }
    }
  },
  mounted () {
    this.userInfo = this.$store.state.userInfo
    this.checkUserInfo()
    console.log(this.userInfo, 'this.userInfo ====')
  },
  methods: {
    btnListFn (key) {
      const heandler = {
        phone: () => {
          this.goVerification('phone')
        },
        email: () => {
          this.goVerification('email')
        },
        loginPwd: () => {
          this.$router.push('/profile/security-password/loginPwd')
        },
        withdrawPwd: () => {
          this.$router.push('/profile/security-password/withdraw')
          // this.goVerification('phone')
        },
        faceID: () => {
          console.log('faceID')
          // this.goVerification('phone')
        },
        patternPwd: () => {
          console.log('patternPwd')
          // this.goVerification('phone')
        }
      }
      heandler[key]()
    },
    goVerification (type) {
      this.$router.push({
        path: '/profile/security-verification/' + type
      })
    },
    checkUserInfo () {
      console.log()
      this.btnList.email.rightStatus = !!this.userInfo.email
      this.btnList.phone.rightStatus = !!this.userInfo.mobile
    }
  }
}
</script>
<style lang="less">
</style>
